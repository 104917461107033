/**
 * Websocketによるチャット機能を提供する
 * @param channel チャンネル名
 * @param seminar_id セミナーID
 * @param chat_properties チャット表示時に関わるカスタマイズ情報
 * 例:
 * {
 *      post_template_html: チャット発言一件分のHTML（デフォルト値あり）
 *      chat_area_id: チャットの表示領域（チャット発言を挿入するタグのID）
 *      chat_post_trigger_id: チャット契機のHTML要素ID
 *      chat_post_event_type: チャットの契機となるHTML要素のどのイベントでチャット送信するか（デフォルトはclick）
 *      chat_post_body_id: チャットの書き込み先の要素ID
 *      chat_information_id: チャットの通知表示領域のID
 *      chat_user_name_class: post_template_html内のユーザ名に置き換える部分のクラス名（デフォルトchat-user-name）
 *      chat_body_class: post_template_html内のチャット発言に置き換える部分のクラス名（デフォルトchat-body）
 *      chat_time_class: post_template_html内のチャット発言時間に置き換える部分のクラス名（デフォルトchat-time）
 *      time_format: チャット発言時間の書式（未実装）
 *      hilight_color: チャット発言時のハイライトエフェクトの色名（デフォルトlemonchiffon）
 *      scroll_down: true→下スクロール、false→上スクロール
 * }
 * @constructor
 *
 * シンプルな利用例：（seminar_id = 1, scope = 0の場合）
 *  nexpro_chat = new NexproChat("ChatChannel", 1, 0, {});
 *  nexpro_chat.init();
 *
 * 表示カスタマイズする場合の利用例：（seminar_id = 1, scope = 0の場合）
 *  nexpro_chat = new NexproChat("ChatChannel", 1, 0, {});
 *      {
 *          post_template_html: '<li><div class ... 中略 ... </div></li>', //独自HTMLタグ
 *          chat_area_id: 'chat-area-id', //画面上のチャット表示領域のID
 *          hilight_color: 'red', //チャット発言時のハイライトエフェクトを赤
 *          scroll_down: false, //上に順次表示するモード
 *      }
 *  );
 *  nexpro_chat.init();
 */
export default function NexproChat(channel, room_id, speaker_type, speaker_id, chat_type, chat_properties, group_ids) {

  //チャット情報保存
  this.channel = channel;
  this.room_id = room_id;
  this.speaker_type = speaker_type;
  this.speaker_id = speaker_id;
  this.chat_type = chat_type;

  this.chat_properties = {
    post_template_html: '<li><div class="chat-content"><h5 class="chat-user-name"></h5><div class="box bg-light-primary chat-body"></div></div><div class="chat-time text-nowrap"></div></li>',
    repost_template_html: '<li class="reverse"><div class="chat-content"><h5 class="chat-user-name"></h5><div class="box bg-light-info chat-body"></div></div><div class="chat-time text-nowrap"></div></li>',
    chat_area_id: "chat-area",
    chat_post_trigger_id: "chat-post-trigger",
    chat_post_event_type: "click",
    chat_post_body_id: "chat-post-body",
    chat_post_information_id: "chat-information",
    chat_user_name_class: "chat-user-name",
    chat_body_class: "chat-body",
    chat_time_class: "chat-time",
    chat_fixed_message: "fixedMessage",
    chat_post_as_fixed: "post-as-fixed-pin",
    chat_div: "chatdiv",
    chat_list: ".chat-list",
    fixed_chat: 'fixed-chat',
    button_container: 'buttonContainer',
    remove_pin: 'remove-pin',
    time_format: "Y/M/D HH:mm:ss",
    highlight_color: "lemonchiffon",
    time_zone: "Asia/Tokyo",
    scroll_down: true,
    post_fix: true,
    connected_callback: null,
  };
  $.extend(this.chat_properties, chat_properties);
  this.post_template = $(this.chat_properties.post_template_html);
  this.repost_template = $(this.chat_properties.repost_template_html);
  this.timestamp = null;
  this.chat_area = $("#" + this.chat_properties.chat_area_id);
  this.scroll_is_top = false;
  this.post_fix = this.chat_properties.post_fix;
  this.fixedChat = document.getElementById(this.chat_properties.fixed_chat);
  this.remove_pin = document.getElementById(this.chat_properties.remove_pin);
  this.connected_callback = this.chat_properties.connected_callback;
  var nexpro_chat = this;
  var chatDiv = document.getElementById(this.chat_properties.chat_div)
  var pin_icon_off_html = "<i class='mdi mdi-pin-off absolute-pin'></i>";
  var pin_icon_html = "<i class='mdi mdi-pin absolute-pin'></i>";
  var fixedMessage = document.getElementById(this.chat_properties.chat_fixed_message);
  var chatPostAsFixed = document.getElementById(this.chat_properties.chat_post_as_fixed);
  var buttonContainer = document.getElementById(this.chat_properties.button_container);
  var chatArea = this.chat_area[0];
  var chatList = document.querySelector(this.chat_properties.chat_list);
  var chatIds = [];
  let is_show_red_point = true; //チャットアイコンに赤点を表示させるかどうかのフラグ
  const chatAreaElement = document.querySelector("#chat-area");
  const chatObserverOptions = {
    rootMargin: '0px',
    threshold: 1,
  };
  const chatObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        $(".ti-comment-alt").removeClass("chat-red-point");
      }
    });
  }
  const chatObserver = new IntersectionObserver(chatObserverCallback, chatObserverOptions);

  if (this.fixedChat != null) {
    $(window).resize(function () {
      nexpro_chat.fixedChat.style.width = chatArea.clientWidth - 16 + "px"
    })
  }
  this.chat = subscriptionRoom();
  //イベント設定
  var event_target = $("#" + nexpro_chat.chat_properties.chat_post_trigger_id);
  var message_body = $("#" + nexpro_chat.chat_properties.chat_post_body_id);
  var information_area = $("#" + nexpro_chat.chat_properties.chat_post_information_id);
  var event_type = nexpro_chat.chat_properties.chat_post_event_type;

  event_target.on(event_type, function () {
    var message = message_body.val().trim();
    if (message == "") {
      return;
    }
    if (message && chatPostAsFixed != null && chatPostAsFixed.classList.contains("mdi-pin")) {
      var result = nexpro_chat.chat.speak(message_body.val(), true);
      togglePin(chatPostAsFixed);
    } else {
      var result = nexpro_chat.chat.speak(message_body.val(), false);
    }

    if (result) {
      nexpro_chat.scrollToNewestPost();
      message_body.val("");
      information_area.val("");
    } else {
      //TODO: 失敗時の挙動が不明（全てtrueで返ってくる）
      information_area.val("エラー発生");
    }
  });

  message_body.on("keyup", function (event) {
    if (event.keyCode == 13 && event.shiftKey) {
      event_target.trigger(event_type);
    }
  });

  this.chat_area.parent().scroll(function () {

    if ($(this).scrollTop() != 0 || nexpro_chat.scroll_is_top == true || nexpro_chat.chat_area[0].scrollHeight == 0) return;
    nexpro_chat.fetchChatsFromServer(false);
  });

  var chatUrl = speaker_type == "TAdminUser" ? "/admin/chat/fetch" : "/mypage/chat/fetch";

  //チャット読み込み処理
  NexproChat.prototype.fetchChatsFromServer = function (scroll) {
    $.ajax({
      url: chatUrl,
      type: "POST",
      data: {
        "room_id": nexpro_chat.room_id,
        "timestamp": nexpro_chat.timestamp
      },
      success: function (response) {
        if (Object.keys(response.data).length === 0 && response.data.constructor === Object) {
          return;
        }
        if (response.status != "200") {
          alert(response.status + ": " + response.message);
          return;
        }
        var checked;
        if (response.data.fixed_chat && response.data.fixed_chat.fixed_message) {
          nexpro_chat.updateFixed(response.data.fixed_chat);
          checked = response.data.fixed_chat.fixed_id;
        }
        if (response.data.timestamp) {
          nexpro_chat.timestamp = response.data.timestamp;
        } else {
          nexpro_chat.scroll_is_top = true;
        }
        //画面上でチャットを送信するとき以外は赤点を表示させない
        is_show_red_point = false;
        if (scroll) {
          $.each(response.data.chats, function (key, chat) {
            if (nexpro_chat.chatListContains(chat['chat_id'])) {
              return;
            }
            nexpro_chat.addOneChatPost(chat, false, checked);//ハイライトエフェクトは無し
          });
        } else {
          $.each(response.data.chats.reverse(), function (key, chat, checked) {
            if (nexpro_chat.chatListContains(chat['chat_id'])) {
              return;
            }
            nexpro_chat.peraddOneChatPost(chat, false, checked);//ハイライトエフェクトは無し
          });
        }
        is_show_red_point = true;
        if (scroll) nexpro_chat.scrollToNewestPost();
      },
      fail: function () {
        alert("読み込みに失敗しました");
      }
    });
  };

  
  //1件分のチャット追加処理
  NexproChat.prototype.addOneChatPost = function (data, highlight_effect, checked) {
    var a_post = this.createPost(data, checked);
    const speaker = data.cat_type;
    const speaker_id = data.speaker_id;
    const oldLastChatElement = chatAreaElement.lastElementChild;
    // チャット欄にチャットが一つもないとき、oldLastChatElementとなる。
    // つまり、チャット欄にチャットが一つもないとき、次のif文は実行されない
    if(is_show_red_point && oldLastChatElement && speaker !== 'private' && this.speaker_id !== speaker_id ) {
      chatObserver.unobserve(oldLastChatElement);
    }

    if (this.chat_properties.scroll_down) {
      this.chat_area.append(a_post);
    } else {
      this.chat_area.prepend(a_post);
    }
    if (highlight_effect) {
      a_post.effect("highlight", {color: nexpro_chat.chat_properties.highlight_color}, 2000);
    }

     // oldLastChatElementとnewLastChatElementは異なる要素を指している
     // newLastChatElementはchatAreaElementの最後の子要素を指している
     // newLastChatElementはchatAreaElementの最後から二番目の子要素を指している(チャットが一つしかないときはnull)
    const newLastChatElement = chatAreaElement.lastElementChild;
    // 赤点の疑似クラスを追加
    if(is_show_red_point && speaker !== 'private' && this.speaker_id !== speaker_id) {
      $(".ti-comment-alt").addClass("chat-red-point");
      chatObserver.observe(newLastChatElement);
    }
  };
  //1件分のチャット追加処理
  NexproChat.prototype.peraddOneChatPost = function (data, highlight_effect, checked) {
    var a_post = this.createPost(data, checked);

    if (this.chat_properties.scroll_down) {
      this.chat_area.prepend(a_post);
    } else {
      this.chat_area.append(a_post);
    }
    if (highlight_effect) {
      a_post.effect("highlight", {color: nexpro_chat.chat_properties.highlight_color}, 2000);
    }
  };
  NexproChat.prototype.createPost = function (data, checked) {
    var a_post = null;
    var repost = false;
    if (this.chat_type == "anonymous") {
      repost = this.speaker_type == data["speaker_type"];
    } else {
      repost = (this.speaker_type == data["speaker_type"] && this.speaker_id == data["speaker_id"]);
    }

    if (repost) {
      a_post = this.repost_template.clone(true);
    } else {
      a_post = this.post_template.clone(true);
    }

    // 非表示のためにIDを付与
    a_post.attr('id', 'li_chat_post_' + data['chat_id']);
    // 正常の場合のhidden_atは、最初の取得にはnullで、チャットを受け取る時はundefined
    if (data['hidden_at'] != undefined) {
      a_post.addClass('hidden_chat');
      a_post.addClass('bg-light-inverse');
    }
    //set user_name
    $.each(a_post.find("." + this.chat_properties.chat_user_name_class), function (index, target) {
      $(target).text(data['name']);
    });
    //set chat_body
    $.each(a_post.find("." + this.chat_properties.chat_body_class), function (index, target) {
      var post_body = data['body'].replace(/(http(s)?:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
      // https://rm.teamfw.net/issues/45301
      // チャット機能で改行を表示できるようにする
      post_body = post_body.replace(/\n/gi, "<br/>");
      $(target).attr('data-id', data['chat_id'])
      $(target).html(post_body);
      if (nexpro_chat.fixedChat != null && repost) {
        if (speaker_type === "TAdminUser" && nexpro_chat.post_fix && data['hidden_at'] == undefined) {
          if (checked == data['chat_id']) {
            $(target).prepend(pin_icon_html);
          } else {
            $(target).prepend(pin_icon_off_html);
          }
        }
      }
      // アドミン側には削除ボタンを出す
      if (channel === 'AdminChatChannel' && data['cat_type'] !== 'private' && data['hidden_at'] == undefined) {
        if (a_post.hasClass('reverse')) {
          $(target).before('<span class="btn chat-hide-btn hide" data-id=' + data['chat_id'] + '></span>');
        } else {
          $(target).after('<span class="btn chat-hide-btn hide" data-id=' + data['chat_id'] + '></span>');
        }
        hide_btn_event($(target).parent('.chat-content'));
      }
    });
    //set chat_time
    $.each(a_post.find("." + this.chat_properties.chat_time_class), function (index, target) {
      var time = moment.tz(data['time'], nexpro_chat.chat_properties.time_zone).format(nexpro_chat.chat_properties.time_format);
      $(target).text(time);
    });

    return a_post;

  }

  NexproChat.prototype.updateFixed = function (data) {
    if (nexpro_chat.fixedChat == null) return;
    if (data['fixed_message'].length > 0) {
      console.log(fixedMessage.offsetHeight);
      nexpro_chat.fixedChat.style.display = "";
      nexpro_chat.fixedChat.classList.remove("fadeOut");
      nexpro_chat.fixedChat.classList.add("fadeIn");
      nexpro_chat.fixedChat.setAttribute('data-id', data['fixed_id']);
      chatDiv.style.overflowY = "scroll";
      var message = data['fixed_message'].trim().replace(/(http(s)?:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
      fixedMessage.innerHTML = message;
      var scroll = false;
      if (Math.floor(chatDiv.scrollHeight) - Math.floor(chatDiv.scrollTop) == chatDiv.clientHeight) {
        scroll = true;
      }
      if (chatList.style.marginTop < nexpro_chat.fixedChat.offsetHeight * 1.4) {
        chatList.style.marginTop = nexpro_chat.fixedChat.offsetHeight * 1.4 + "px"; // 一番上のメッセージも見えるようにする
      }
      if (scroll) nexpro_chat.scrollToNewestPost();
      var max = 110;
      if (data['fixed_message'].match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/)) {
        max = 55;
      }
      if (data['fixed_message'].trim().length < max) {
        buttonContainer.style.display = "none";
        fixedMessage.classList.remove("collapse", "show");
      } else {
        buttonContainer.style.display = "block";
        fixedMessage.classList.add("collapse");
      }
      if (nexpro_chat.remove_pin != null) {
        nexpro_chat.remove_pin.classList.remove("fadeOut");
        nexpro_chat.remove_pin.classList.add("fadeIn");
        nexpro_chat.remove_pin.style.display = "";
        nexpro_chat.remove_pin.style.cursor = "pointer";
        $(nexpro_chat.remove_pin).tooltip();
      }
      nexpro_chat.fixedChat.style.width = this.chat_area[0].clientWidth - 16 + "px"
    } else {
      nexpro_chat.fixedChat.classList.remove("fadeIn");
      nexpro_chat.fixedChat.classList.add("fadeOut");
      fixedMessage.classList.remove("show");
      fixedMessage.textContent = '';
      if (nexpro_chat.remove_pin != null) {
        nexpro_chat.remove_pin.classList.remove("fadeIn");
        nexpro_chat.remove_pin.classList.add("fadeOut");
        nexpro_chat.remove_pin.style.cursor = "auto";
        $(nexpro_chat.remove_pin).tooltip('dispose');
      }
    }
  }

  NexproChat.prototype.scrollToNewestPost = function () {
    var scrollVal = 0;//スクロール位置

    if (this.chat_properties.scroll_down) {//上下どちらが最新かにより分岐
      scrollVal = this.chat_area[0].scrollHeight;
    } else {
      scrollVal = 0;
    }
    //scroll and animation
    this.chat_area.parent().animate({scrollTop: scrollVal}, 'slow');
  };

  //初期設定
  NexproChat.prototype.init = function () {
  };

  //初期設定
  NexproChat.prototype.chatListContains = function (chatId) {
    chatId = parseInt(chatId);
    if ($.inArray(chatId, chatIds) >= 0) {
      console.log('found!!')
      return true;
    } else {
      chatIds.push(chatId);
      return false;
    }
  };

  NexproChat.prototype.changeRoom = function (room_id, is_post_fix) {
    nexpro_chat.room_id = room_id;
    nexpro_chat.chat.unsubscribe();
    nexpro_chat.chat_area.empty();
    chatIds = [];
    if (nexpro_chat.remove_pin != null) nexpro_chat.remove_pin.classList.add("fadeOut");
    if (nexpro_chat.fixedChat != null) nexpro_chat.fixedChat.style.display = "none";
    nexpro_chat.timestamp = ""
    nexpro_chat.scroll_is_top = false;
    nexpro_chat.post_fix = is_post_fix;
    nexpro_chat.chat = subscriptionRoom();
  };


  function subscriptionRoom() {
    //チャット接続
    return App.cable.subscriptions.create({
      channel: nexpro_chat.channel,
      room_id: nexpro_chat.room_id,
      speaker_type: speaker_type,
      speaker_id: speaker_id,
      group_ids: group_ids
    }, {
      connected: function () {
        //初期データ読み込み
        nexpro_chat.fetchChatsFromServer(true);
      },

      disconnected: function () {
      },

      received: function (param) {
        // チャット非表示処理
        if (param['hide_chat_id'] !== undefined) {
          var li_chat_post = $('#li_chat_post_' + param['hide_chat_id']);
          if (channel === 'AdminChatChannel') {
            li_chat_post.addClass('hidden_chat');
            li_chat_post.addClass('bg-light-inverse');
            li_chat_post.find('.chat-hide-btn').remove();
            li_chat_post.find('.absolute-pin').remove();
          } else {
            li_chat_post.fadeOut(600, 'swing', function () {
              $(this).remove();
            })
          }
          if (param['fixed_id'] !== undefined) {
            nexpro_chat.updateFixed(param);
          }
          return;
        }
        var data = param;
        if (nexpro_chat.chatListContains(data['chat_id'])) {
          return;
        }
        var fixedOnly = {
          fixed_id: null,
          fixed_message: null
        }
        if (data['fixed_id']) {
          if (data['chat_id'] && data['time']) {
            fixedOnly = {
              fixed_id: data['fixed_id'],
              fixed_message: data['body'],
            };
            nexpro_chat.updateFixed(fixedOnly);
          } else {
            nexpro_chat.updateFixed(data);
            return;
          }
        }

        var scrollBack = false;
        if (Math.floor(chatDiv.scrollHeight) - Math.floor(chatDiv.scrollTop) == chatDiv.clientHeight) {
          scrollBack = true;
        }
        nexpro_chat.addOneChatPost(data, true, fixedOnly.fixed_id);
        if (scrollBack) {
          nexpro_chat.scrollToNewestPost();
        }
      },

      speak: function (message, mark_as_fixed) {
        var mark_as_fixed = mark_as_fixed || false;
        if (mark_as_fixed === true) {
          $('.absolute-pin').attr('class', 'mdi absolute-pin mdi-pin-off');
        }
        return nexpro_chat.chat.perform('speak', {message: message, mark_as_fixed: mark_as_fixed});
      },

      markAsFixed: function (data) {
        $('.absolute-pin').attr('class', 'mdi absolute-pin mdi-pin-off');
        return nexpro_chat.chat.perform('mark_as_fixed', data);
      }
    });
  }
}
